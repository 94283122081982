import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import BackButton from '@luna-protocol/core/src/components/BackButton/BackButton';
import Body from '@luna-protocol/core/src/components/Body/Body';
import Button from '@luna-protocol/core/src/components/Button/Button';
import Card from '@luna-protocol/core/src/components/Card/Card';
import LineBreak from '@luna-protocol/core/src/components/LineBreak/LineBreak';
import { AccessoryDetail, KVPair, DealInformation, OEMType as EOEMType } from '@luna-protocol/core/src/types.ts';
import { Spinner } from '@luna-protocol/core/src/components/Spinner/Spinner.tsx';
import { useAuth } from '@luna-protocol/core/src/utils/useAuth.ts';
import useToast from '@luna-protocol/core/src/utils/useToast.ts';
import coreMessages from '@luna-protocol/core/src/utils/Core.messages.ts';
import CustomerName from '@luna-protocol/core/src/components/CustomerName/CustomerName';
import { USDollar } from '@luna-protocol/core/src/utils/constants/format.ts';
import config from '@luna-protocol/core/src/utils/feature-flags.json';

import { AppContext } from '../../AppContext.tsx';
import AccessoriesList from '../Accessories/AccessoriesList.tsx';
import AddAccessories from '../Accessories/AddAccessories.tsx';
import FeesDeductions from '../FeesDeductions/FeesDeductions.tsx';
import ProductSummary from '../ProductSummary/ProductSummary.tsx';
import VinLookup from '../VinLookup/VinLookup.tsx';
import messages from './AddProducts.messages.ts';
import Totals from '../Totals/Totals.tsx';
import { usePostBuildDeal, usePostLienDeal } from '../../queries/usePostBuildDeal.ts';
import { usePutSerialNumbers } from '../../queries/usePutSerialNumbers.ts';
import { SerialNumber } from '../../types.ts';
import './AddProducts.scss';
import { useQuery, useQueryClient } from 'react-query';
import { RETRIEVE_DEAL, RETRIEVE_SERIAL_NUMBERS } from '../../queries/constants.ts';

import { getSerialNumbers } from '../../queries/useGetSerialNumbers.ts';
import { getDealInformation } from '../../queries/useGetDealInformation.ts';
import { getDealer } from '../../queries/useGetDealerInfo.ts';
import { getRatesheet } from '../../queries/useGetRatesheet.ts';

const caBackendProducts = ['Theft Deterrent Device', 'Surface Protection Product', 'EV Charging Station'];

const caFrontendProducts = ['Emissions Testing Charge', 'State Emissions Certification Fee or Exemption Fee'];

const AddProducts = () => {
  useAuth();
  const { formatMessage } = useIntl();
  const {
    deal,
    setDeal,
    loanApplicationID,
    customerName,
    OEMType,
    serialNumbers,
    setSerialNumbers,
    setDealerInfo,
    dealerFlowTemplate,
  } = useContext(AppContext);
  const queryClient = useQueryClient();
  const { refetch } = useQuery([RETRIEVE_SERIAL_NUMBERS, loanApplicationID], { enabled: false });
  const { postBuildDealDetails } = usePostBuildDeal();
  const { postLienProvider } = usePostLienDeal();
  const { putUpdatedProductSummary } = usePutSerialNumbers();
  const navigate = useNavigate();
  const { createToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      if (loanApplicationID === undefined || OEMType === undefined) {
        return;
      }
      const serialNumbersData = await getSerialNumbers(loanApplicationID);

      const dealInfo = await getDealInformation(loanApplicationID);
      const dealerInfo = await getDealer(dealInfo?.dealer_id);

      let filterType = OEMType;
      if (config.FF_OEMTemplatesEnabled && dealerFlowTemplate && OEMType === EOEMType.CUSTOM) {
        filterType = dealerFlowTemplate.name as EOEMType;
      }

      const rateSheet = await getRatesheet(filterType);

      const {
        maxFrontEndPercentNewProduct,
        maxBackendPercentNewProduct,
        maxBackendAmountNewProduct,
        maxFrontEndPercentUsedProduct,
        maxBackendPercentUsedProduct,
        maxBackendAmountUsedProduct,
        maxOverallNewProduct,
        maxOverallUsedProduct,
        maxBackendOverall,
      } = rateSheet;

      dealInfo.totals = dealInfo.totals || {};
      dealInfo.totals.maxFrontEndPercentNewProduct = maxFrontEndPercentNewProduct;
      dealInfo.totals.maxBackendPercentNewProduct = maxBackendPercentNewProduct;
      dealInfo.totals.maxBackendAmountNewProduct = maxBackendAmountNewProduct;
      dealInfo.totals.maxFrontEndPercentUsedProduct = maxFrontEndPercentUsedProduct;
      dealInfo.totals.maxBackendPercentUsedProduct = maxBackendPercentUsedProduct;
      dealInfo.totals.maxBackendAmountUsedProduct = maxBackendAmountUsedProduct;
      dealInfo.totals.maxOverallNewProduct = maxOverallNewProduct;
      dealInfo.totals.maxOverallUsedProduct = maxOverallUsedProduct;
      dealInfo.totals.maxBackendOverall = maxBackendOverall;

      if (dealInfo.additions === undefined || dealInfo.additions === null) {
        dealInfo.additions = [];
      }
      // additions, Fees and Taxes
      const defaultAdditions = rateSheet.frontEndProducts;
      for (const addition of defaultAdditions) {
        if (dealInfo.additions.find((item: KVPair) => item.key === addition) === undefined) {
          dealInfo.additions = [...dealInfo.additions, { key: addition, value: 0 }];
        }
      }
      if (dealerInfo?.address.state === 'FL') {
        if (dealInfo.additions.find((item: KVPair) => item.key === 'FL Doc Stamp Tax') === undefined) {
          dealInfo.additions = [...dealInfo.additions, { key: 'FL Doc Stamp Tax', value: 0 }];
        }
      }

      if (dealInfo.backend_products === undefined || dealInfo.backend_products === null) {
        dealInfo.backend_products = [];
      }
      const defaultBackendProducts = rateSheet.backEndProducts;
      for (const backendProduct of defaultBackendProducts) {
        if (dealInfo.backend_products.find((item: KVPair) => item.key === backendProduct) === undefined) {
          dealInfo.backend_products = [...dealInfo.backend_products, { key: backendProduct, value: 0 }];
        }
      }

      dealInfo.backend_products.sort((a, b) => {
        if (a.key.startsWith('Other-')) return 1;
        if (b.key.startsWith('Other-')) return -1;
        if (a.key === 'Other') return 1;
        if (b.key === 'Other') return -1;
        return 0;
      });

      if (dealerInfo?.address.state === 'CA') {
        caBackendProducts.forEach(product => {
          if (dealInfo.backend_products.find((item: KVPair) => item.key === product) === undefined) {
            dealInfo.backend_products = [...dealInfo.backend_products, { key: product, value: 0 }];
          }
        });
        caFrontendProducts.forEach(product => {
          if (dealInfo.additions.find((item: KVPair) => item.key === product) === undefined) {
            dealInfo.additions = [...dealInfo.additions, { key: product, value: 0 }];
          }
        });
      } else {
        dealInfo.backend_products = dealInfo.backend_products.filter(
          (item: KVPair) => !caBackendProducts.includes(item.key),
        );
        dealInfo.additions = dealInfo.additions.filter((item: KVPair) => !caFrontendProducts.includes(item.key));
      }

      if (dealInfo.deductions === undefined || dealInfo.deductions === null) {
        dealInfo.deductions = [];
      }
      const defaultDeductions = ['Down payment', 'Trade-in value', 'Rebate'];
      for (const deduction of defaultDeductions) {
        if (dealInfo.deductions.find((item: KVPair) => item.key === deduction) === undefined) {
          dealInfo.deductions = [...dealInfo.deductions, { key: deduction, value: 0 }];
        }
      }
      dealInfo.deductions = dealInfo.deductions.map((item: KVPair) => {
        return { key: item.key, value: Math.abs(item.value || 0) };
      });

      setSerialNumbers(serialNumbersData);
      setDealerInfo(dealerInfo);
      setDeal(dealInfo);
      setIsLoading(false);
    })();
  }, []);

  const saveDetails = (onSaveSuccess: () => void) => {
    if (deal === undefined) {
      return;
    }
    // deal sent to API must have 0 values removed
    const apiDeal = {
      dealer_id: deal?.dealer_id || '', // API ignores this field
      additions: deal?.additions?.filter((item: KVPair) => item.value !== 0) || [],
      backend_products: deal?.backend_products?.filter((item: KVPair) => item.value !== 0) || [],
      deductions:
        deal?.deductions
          ?.filter((item: KVPair) => item.value !== 0)
          .map((item: KVPair) => {
            if (item.name) {
              return { key: item.key, value: (item.value || 0) * -1, name: item.name } as KVPair;
            }
            return { key: item.key, value: (item.value || 0) * -1 } as KVPair;
          }) || [],
      accessories: deal?.accessories?.filter((item: AccessoryDetail) => item.price !== 0) || [],
    } as Partial<DealInformation>;

    const tradeInDeal = {
      deduction:
        deal?.deductions
          ?.filter((item: KVPair) => item.value !== 0 && item.name)
          .map((item: KVPair) => {
            return { key: item.key, value: item.value || 0, name: item.name } as KVPair;
          }) || [],
    };

    postBuildDealDetails(apiDeal, {
      onSuccess: () => {
        if (onSaveSuccess) {
          queryClient.invalidateQueries([RETRIEVE_DEAL, loanApplicationID]);
          onSaveSuccess();
        }
      },
    });

    postLienProvider(tradeInDeal, {
      onSuccess: () => {
        if (onSaveSuccess) {
          queryClient.invalidateQueries([RETRIEVE_DEAL, loanApplicationID]);
          onSaveSuccess();
        }
      },
    });
  };

  const checkLimits = () => {
    if (deal?.totals?.isFrontendLTVExceed) {
      createToast({
        title: formatMessage(messages.frontEndLTVexceed, {
          frontEndLTVLimit:
            serialNumbers?.serial_numbers[0]?.condition === 'new'
              ? deal?.totals?.maxFrontEndPercentNewProduct
              : deal?.totals?.maxFrontEndPercentUsedProduct,
        }),
        status: 'error',
      });
    }

    if (deal?.totals?.isBackendLTVExceed) {
      createToast({
        title: formatMessage(messages.backEndLTVexceed, {
          backEndLTVLimit:
            serialNumbers?.serial_numbers[0]?.condition === 'new'
              ? deal?.totals?.maxBackendPercentNewProduct
              : deal?.totals?.maxBackendPercentUsedProduct,
        }),
        status: 'error',
      });
    }

    if (deal?.totals?.isBackendValueExceed) {
      createToast({
        title: formatMessage(messages.backEndValueExceed, {
          backEndValueLimit: USDollar.format(deal?.totals?.backendPriceLimit || 0),
        }),
        status: 'error',
      });
    }

    if (deal?.totals?.isFrontendLTVExceed || deal?.totals?.isBackendLTVExceed || deal?.totals?.isBackendValueExceed) {
      return false;
    }

    return true;
  };

  const saveForLater = () => {
    saveDetails(() => {
      navigate('/prequalification');
    });
  };

  const viewOffers = () => {
    /*
    Check if the GAP Waiver and GAP Insurance backend products are both selected, if they are show a toast and
    do not save
     */
    const selectedProducts = deal?.backend_products?.filter((item: KVPair) => item.value !== 0);
    if ((selectedProducts?.filter(e => e.key.includes('GAP')).length || 0) > 1) {
      createToast({
        title: `${formatMessage(messages.multipleGAPProducts)}`,
        status: 'error',
      });
      return;
    }

    saveDetails(() => {
      navigate('/processing');
    });
  };

  return (
    <>
      <BackButton path="/amend_details" inverted />
      <Banner alternate>{formatMessage(messages.title)}</Banner>
      <CustomerName applicantName={customerName || ''} />
      <Body fullWidth stretched>
        {!isLoading ? (
          <div className="add-products--container">
            <div className="inner-container left">
              <Card>
                <Totals />
              </Card>
              <LineBreak largeMargin />
              <Card>
                <FeesDeductions />
              </Card>
              <>
                <h3>{formatMessage(messages.accessorySummaryTitle)}</h3>
                <Card>
                  <AccessoriesList />
                </Card>
                <h3>{formatMessage(messages.addAccessoriesTitle)}</h3>
                <Card>
                  <AddAccessories />
                </Card>
              </>
            </div>
            <div className="inner-container right">
              <Card variant="grey">
                <ProductSummary
                  editable={false}
                  approvedAmount={serialNumbers?.approved_amount}
                  showConfirmButton={true}
                  ButtonText="Go Back to edit the Base Product"
                  onSubmit={() => navigate('/amend_details')}
                />
                <h4>{formatMessage(messages.vinTitle)}</h4>
                <p>{formatMessage(messages.vinDescription)}</p>
                {serialNumbers?.serial_numbers.map((item: SerialNumber, index: number) => (
                  <VinLookup
                    key={`serial-${index}`}
                    SerialNumber={item.serial_number}
                    UpdateSerialNumber={NewSerialNumber => {
                      if (serialNumbers.serial_numbers[index].serial_number === NewSerialNumber) {
                        return;
                      }
                      serialNumbers.serial_numbers[index].serial_number = NewSerialNumber;
                      putUpdatedProductSummary(
                        {
                          loan_application_id: loanApplicationID || '',
                          item_name: serialNumbers.item_name,
                          serial_numbers: serialNumbers.serial_numbers,
                        },
                        {
                          onSuccess: () => {
                            refetch();
                            createToast({
                              title: `${formatMessage(coreMessages.success)}`,
                              status: 'success',
                            });
                          },
                        },
                      );
                    }}
                  />
                ))}
                <LineBreak largeMargin />

                <Button
                  className="build-deal--button"
                  variant="primary"
                  fullWidth
                  onClick={() => {
                    if (checkLimits()) {
                      saveForLater();
                    }
                  }}>
                  {formatMessage(messages.saveButton)}
                </Button>
                <Button
                  className="build-deal--button"
                  variant="secondary"
                  fullWidth
                  onClick={() => {
                    if (checkLimits()) {
                      viewOffers();
                    }
                  }}>
                  {formatMessage(messages.viewButton)}
                </Button>
              </Card>
            </div>
          </div>
        ) : (
          <Spinner color="black" />
        )}
      </Body>
    </>
  );
};

export default AddProducts;

import { ColumnDef, createColumnHelper, SortingState } from '@tanstack/react-table';
import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import arrow from '@luna-protocol/core/src/assets/backArrow-inverted.svg';
import Cross from '@luna-protocol/core/src/assets/cross.svg';
import Tick from '@luna-protocol/core/src/assets/tick.svg';
import Body from '@luna-protocol/core/src/components/Body/Body';
import Button from '@luna-protocol/core/src/components/Button/Button';
import ButtonGroup from '@luna-protocol/core/src/components/ButtonGroup/ButtonGroup';
import DataTable from '@luna-protocol/core/src/components/DataTable/DataTable';
import { GLOBAL_LIMIT } from '@luna-protocol/core/src/utils/constants/constants.ts';
import { USDate, USDollar } from '@luna-protocol/core/src/utils/constants/format.ts';
import { getUserTypeFromToken } from '@luna-protocol/core/src/utils/getCustomerIdFromToken.ts';
import { useAuth } from '@luna-protocol/core/src/utils/useAuth.ts';

import { AppContext } from '../../AppContext';
import { useGetCustomers } from '../../queries/useGetCustomers';
import { PrequalificationData } from '../../types';
import useClearSessionData from '../../utils/hooks/useClearSessionData';
import UpdateBranding from '../UpdateBranding/UpdateBranding.tsx';
import './Prequalification.scss';
import messages from './PrequaliticationList.messages';
import useCustomerStatusCheck from '../../utils/hooks/useCustomerStatusCheck.ts';

// AKA Customers
const PrequalificationList = () => {
  useAuth();
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [orderCol, setOrderCol] = useState('created_at');
  const [orderDir, setOrderDir] = useState('DESC');
  const [searchKey, setSearchKey] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading = true } = useGetCustomers({
    searchKey,
    triggerSearch,
    page: currentPage,
    page_size: GLOBAL_LIMIT,
    orderColumn: orderCol,
    orderDirection: orderDir,
  });

  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { setLoanApplicationID, dealerFlowTemplate, setDealerFlowTemplate } = useContext(AppContext);
  const clearSessionData = useClearSessionData();
  const { checkStatus } = useCustomerStatusCheck();

  const token = localStorage.getItem('user_token');
  if (token === null) {
    return true;
  }

  const flowTemplate = localStorage.getItem('flow_template');
  if (!dealerFlowTemplate && flowTemplate) {
    setDealerFlowTemplate(JSON.parse(flowTemplate));
  }

  const user_type = getUserTypeFromToken(token);
  const SERVICE_PROVIDER = user_type === 'service_provider';

  const columnHelper = createColumnHelper<PrequalificationData>();

  const viewDeal = (id: string) => {
    clearSessionData();
    setLoanApplicationID(id);
    navigate('/amend_details');
  };

  const columns: ColumnDef<PrequalificationData, string | boolean>[] = [];

  if (SERVICE_PROVIDER) {
    columns.push(
      columnHelper.accessor('dealer_name', {
        cell: info => info.getValue(),
        header: `${formatMessage(messages.dealerName)}`,
        enableSorting: true,
        enableColumnFilter: false,
      }) as ColumnDef<PrequalificationData, string | boolean>,
    );
  }

  columns.push(
    columnHelper.accessor('first_name', {
      cell: info => info.getValue(),
      header: `${formatMessage(messages.firstName)}`,
      enableSorting: true,
      enableColumnFilter: false,
    }) as ColumnDef<PrequalificationData, string | boolean>,
    columnHelper.accessor('last_name', {
      cell: info => info.getValue(),
      header: `${formatMessage(messages.lastName)}`,
      enableSorting: true,
      enableColumnFilter: false,
    }) as ColumnDef<PrequalificationData, string | boolean>,
    columnHelper.accessor('make', {
      cell: info => `${info.getValue()} ${info.row.original.model}`,
      header: `${formatMessage(messages.make)}`,
      enableSorting: false,
      enableColumnFilter: false,
    }) as ColumnDef<PrequalificationData, string | boolean>,
    columnHelper.accessor('borrowing_amount', {
      cell: info => USDollar.format(parseFloat(info.getValue())),
      header: `${formatMessage(messages.borrowing_amount)}`,
      enableSorting: true,
      enableColumnFilter: false,
    }) as ColumnDef<PrequalificationData, string | boolean>,
    columnHelper.accessor('created_at', {
      cell: info => USDate.format(new Date(info.getValue())),
      header: `${formatMessage(messages.created_at)}`,
      enableSorting: true,
      enableColumnFilter: false,
    }) as ColumnDef<PrequalificationData, string | boolean>,
    columnHelper.accessor('customer_status', {
      cell: info => info.getValue() || 'unknown',
      header: `${formatMessage(messages.status)}`,
      enableSorting: false,
      enableColumnFilter: false,
    }) as ColumnDef<PrequalificationData, string | boolean>,
    columnHelper.accessor('idv_status', {
      cell: info => (info.getValue() ? <img src={Tick} alt="React Logo" /> : <img src={Cross} alt="React Logo" />),
      header: `${formatMessage(messages.idv_status)}`,
      enableSorting: false,
      enableColumnFilter: false,
    }) as ColumnDef<PrequalificationData, string | boolean>,
    columnHelper.display({
      cell: props => (
        <Button
          className="prequal-list-view-deal-button"
          fullWidth={false}
          variant="secondary"
          width={120}
          testId={`prequalification-action-${props.row.original.loan_application_id}`}
          onClick={event => {
            event.stopPropagation();
            viewDeal(props.row.original.loan_application_id);
          }}
          disabled={!props.row.original?.customer_accepted_prequal || checkStatus(props.row.original.customer_status)}>
          <span className="view-deal-button--text">{formatMessage(messages.actionButton)}</span>
          <img className="view-deal-button--icon" src={arrow} aria-label="View deal button" alt="View deal button" />
        </Button>
      ),
      header: `${formatMessage(messages.actionHeader)}`,
    }) as ColumnDef<PrequalificationData, string | boolean>,
  );

  const changeSort = (state: SortingState) => {
    if (state.length > 1) {
      throw new Error('multiple sorts are not supported');
    }

    if (state.length === 0) {
      // return to default
      setOrderCol('created_at');
      setOrderDir('DESC');
      return;
    }

    if (state[0].desc) {
      setOrderDir('DESC');
    } else {
      setOrderDir('ASC');
    }

    if (state[0].id == 'borrowing_amount') {
      setOrderCol('pre_qualified_amount_amount');
    } else {
      setOrderCol(state[0].id);
    }
  };

  return (
    <Body fullWidth stretched>
      <UpdateBranding />
      <h1>{formatMessage(messages.title)}</h1>
      <div className="prequalification-input-wrapper">
        <div className="prequalification-search-bar-container">
          <input
            type="search"
            className="text-input"
            onChange={e => {
              setSearchKey(e.target.value);
              if (e.target.value === '') setTriggerSearch(false);
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setTriggerSearch(true);
                setCurrentPage(0);
              }
            }}
            name="loan_application_id"
            value={searchKey || ''}
            placeholder="Search for a customer using last name, reference number, etc..."
          />
        </div>
        {!SERVICE_PROVIDER ? (
          <div className="prequalification-button-container">
            <ButtonGroup align="right">
              <Button
                type="submit"
                variant="secondary"
                onClick={() => navigate('/new_lead')}
                width={210}
                testId="prequalification-new-button">
                {formatMessage(messages.startPrequalification)}
              </Button>
              <Button
                type="submit"
                variant="secondary"
                onClick={() => navigate('/retrieve')}
                width={210}
                testId="prequalification-retrieve-button">
                {formatMessage(messages.retrieve)}
              </Button>
            </ButtonGroup>
          </div>
        ) : null}
      </div>

      <DataTable
        index={5}
        data={data?.data || []}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        columns={columns as ColumnDef<unknown, any>[]}
        isLoading={isLoading}
        onRowClick={row => {
          clearSessionData();
          // @ts-expect-error - row is not a Row type
          navigate(`/deal_status/${row.loan_application_id}?customer_name=${row.first_name} ${row.last_name}`);
        }}
        paginationEnabled={true}
        total={data?.totalCount}
        currentPage={currentPage}
        changePage={n => setCurrentPage(n)}
        sortedEnabled={true}
        onSortingChange={changeSort}
      />
    </Body>
  );
};

export default PrequalificationList;
